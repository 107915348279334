import { useForm } from "react-hook-form";
import classNames from "classnames";

import { basics } from "config";

import Button from "./Button";

const RegistrationForm = ({
  fixedName,
  fixedEmail,
  showOrganization,
  showQuestions,
  showLunch,
  showPrintouts,
  showParties,
  showDonation,
  questions,
  onSubmit,
  className,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const doSubmit = (data) => {
    const formData = {
      name: data.name,
      email: data.email,
      organization: data.organization,
      products: Object.keys(data)
        .filter((key) => key.indexOf("product__") !== -1)
        .filter((key) => !!data[key])
        .map((key) => {
          const id = parseInt(key.replace("product__", ""));

          if (id === 58) {
            return {
              id,
              price: parseFloat(data[key]),
            };
          }

          console.log(key, data[key]);

          if (data[key] === "true" || data[key] === true) {
            return { id };
          } else {
            return {
              id,
              variation: parseInt(data[key]),
            };
          }
        }),
      responses: Object.keys(data)
        .filter((key) => key.indexOf("question__") !== -1 && !!data[key])
        .map((key) => ({
          question_id: parseInt(key.replace("question__", "")),
          response: data[key],
        })),
    };

    onSubmit(formData);
  };

  return (
    <form
      onSubmit={handleSubmit(doSubmit)}
      className={classNames(
        "grid grid-cols-4 gap-4 row-gap-6 max-w-lg",
        className
      )}
    >
      <div
        className={classNames("form-field form-field--required col-span-4", {
          "form-field--error": !!errors.name,
        })}
      >
        <label className="form-field__label" htmlFor="field">
          Vaše jméno a příjmení
        </label>
        <div className="form-field__wrapper form-field__wrapper--shadowed">
          <input
            type="text"
            className="text-input form-field__control form-field--required"
            defaultValue={fixedName}
            readOnly={!!fixedName}
            {...register("name", { required: true, maxLength: 128 })}
          />
        </div>
        {errors.name && (
          <div className="form-field__error">
            Bez vašeho jména se neobejdeme.
          </div>
        )}
        {!errors.name && fixedName && (
          <div className="form-field__help-text">
            Vaše jméno bylo převyplněno z pirátské identity.
          </div>
        )}
      </div>

      <div
        className={classNames("form-field form-field--required col-span-4", {
          "form-field--error": !!errors.email,
        })}
      >
        <label className="form-field__label" htmlFor="field">
          Váš email
        </label>
        <div className="form-field__wrapper form-field__wrapper--shadowed">
          <input
            type="text"
            className="text-input form-field__control form-field--required"
            defaultValue={fixedEmail}
            readOnly={!!fixedEmail}
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              maxLength: 128,
            })}
          />
        </div>
        {errors.email && (
          <div className="form-field__error">Zadejte prosím platný email.</div>
        )}
        {!errors.email && fixedName && (
          <div className="form-field__help-text">
            Váš email bylo převyplněn z pirátské identity.
          </div>
        )}
      </div>

      {showOrganization && (
        <div className="form-field col-span-4">
          <label className="form-field__label" htmlFor="field">
            Organizace
          </label>
          <div className="form-field__wrapper form-field__wrapper--shadowed">
            <input
              type="text"
              className="text-input form-field__control"
              defaultValue=""
              {...register("organization")}
            />
          </div>
        </div>
      )}

      {showLunch && (
        <div className="form-field col-span-4">
          <label className="form-field__label" htmlFor="field">
            Stravování (120 Kč)
          </label>
          <div className="form-field__wrapper  form-field__wrapper--shadowed">
            <div className="select">
              <select
                className="select__control form-field__control col-span-4"
                defaultValue=""
                {...register("product__10")}
              >
                <option value="">nepotřebuji</option>
                <option value="2">
                  chci objednat oběd na zasedání (bez omezení)
                </option>
                <option value="3">
                  chci objednat oběd na zasedání (vegetarián)
                </option>
              </select>
            </div>
          </div>
        </div>
      )}

      {showParties && (
        <div className="form-field col-span-4">
          <label className="form-field__label">Parties</label>
          <div className="form-field__wrapper">
            <div className="checkbox form-field__control">
              <input
                type="checkbox"
                defaultValue="true"
                {...register("product__11")}
              />
              <label htmlFor="checkbox_1">Účast na pre-party</label>
            </div>
            <div className="checkbox form-field__control">
              <input
                type="checkbox"
                defaultValue="true"
                {...register("product__12")}
              />
              <label htmlFor="checkbox_2">Účast na afterparty</label>
            </div>
          </div>
        </div>
      )}

      {showPrintouts && (
        <div className="form-field col-span-4">
          <label className="form-field__label" htmlFor="field">
            Tištěné dokumenty (30 Kč)
          </label>
          <div className="form-field__wrapper ">
            <div className="radio form-field__control">
              <label>
                <input
                  type="radio"
                  name="docs"
                  value="true"
                  {...register("product__18")}
                />
                <span>Ano</span>
              </label>
            </div>
            <div className="radio form-field__control">
              <label>
                <input
                  type="radio"
                  name="docs"
                  value=""
                  defaultChecked
                  {...register("product__18")}
                />
                <span>Ne</span>
              </label>
            </div>
          </div>
        </div>
      )}

      {showQuestions &&
        questions.map((question) => (
          <div className="form-field col-span-4" key={question.id}>
            <label className="form-field__label" htmlFor="field">
              {question.question}
            </label>
            <div className="form-field__wrapper form-field__wrapper--shadowed">
              <textarea
                className="text-input form-field__control col-span-4"
                rows="3"
                cols="40"
                placeholder={question.help}
                {...register(`question__${question.id}`)}
              ></textarea>
            </div>
          </div>
        ))}

      {showDonation && (
        <div
          className={classNames("form-field col-span-4 max-w-xs", {
            "form-field--error": !!errors.product__58,
          })}
        >
          <label className="form-field__label" htmlFor="field">
            Dobrovolný příspěvek na pořádání {basics.appTitle}
          </label>
          <div className="form-field__wrapper form-field__wrapper--shadowed">
            <input
              type="number"
              className="text-input text-input--has-addon-r form-field__control"
              defaultValue="0"
              min="0"
              {...register("product__58", {
                pattern: /^\d+$/i,
              })}
            />
            <div className="text-input-addon text-input-addon--r">Kč</div>
          </div>
          {errors.product__58 && (
            <div className="form-field__error">
              Zadejte prosím platnou částku, nebo pole nechte prázdné.
            </div>
          )}
          {!errors.product__58 && (
            <div className="form-field__help-text">
              Pokud nám chcete přispět na pořádání {basics.appTitle}, můžete váš
              příspěvek uvést zde.
            </div>
          )}
        </div>
      )}

      <Button
        className="text-lg max-w-full col-span-2"
        bodyClassName="whitespace-no-wrap"
        color="black"
        type="submit"
      >
        Potvrdit
      </Button>
    </form>
  );
};

export default RegistrationForm;
