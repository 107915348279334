import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

const Party = () => {
  return (
    <article className="w-full">
      <PageTitle>Preparty a Afterparty</PageTitle>

      <Helmet>
        <title>Preparty a Afterparty | {basics.appTitle}</title>
        <meta property="og:title" content="Preparty a Afterparty" />
      </Helmet>

      <div className="content-block">
        <h2>Warm-up Party ve Spotu</h2>
        <p>
          Pirate DJs Vás zvou na páteční warm-up do libereckého klubu Spot.<br/>
          Program se ladí.
        </p>

        <h2>Afterparty v Rakvi</h2>
        <p>
          Po skončení oficiální části zasedání bude v hlavním sále Kulturního Domu odstartována Afterparty.<br/>
          Program se ladí. Konec okolo 2.00 v neděli ráno.
        </p>

      </div>
    </article>
  );
};

export default Party;
