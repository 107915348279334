import { Link } from "react-router-dom";
import classNames from "classnames";

import { Card, CardBody } from "./cards";

const CrossroadCard = ({
  children,
  title,
  accent,
  className,
  underConstruction,
  link,
  ...props
}) => {
  const cls = classNames(className, "cf-crossroad-card", {
    [`bg-opacity-75 hover:bg-opacity-100 transition-all duration-100`]:
      !underConstruction,
    "cf-crossroad-card--disabled": underConstruction,
    "cursor-not-allowed": underConstruction,
  });
  const body = (
    <Card className={cls} {...props}>
      <CardBody className="cf-crossroad-card__body text-center">
        <h2 className="head-heavy-sm">{title}</h2>
        {children}
      </CardBody>
    </Card>
  );

  if (link) {
    return (
      <Link to={link} className="hover:no-underline">
        {body}
      </Link>
    );
  }

  return body;
};

export default CrossroadCard;
