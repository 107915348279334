import { NavLink } from "react-router-dom";
import classNames from "classnames";

import { basics, nav } from "config";

const MobileNav = ({ className, isOpen, onClose, ...props }) => {
  return (
    <nav
      className={classNames("cf-mobile-nav md:hidden", {
        hidden: !isOpen,
      })}
    >
      <h1 className="head-alt-lg mb-2">{basics.appTitle}</h1>
      <button className="head-alt-base mb-8" onClick={onClose}>
        Zavřít
      </button>
      <ul className="vertical-navbar__items">
        <li>
          <NavLink to="/">Rozcestník</NavLink>
        </li>
        {nav
          .filter((navItem) => !!navItem.link)
          .map((navItem) => (
            <li key={navItem.link}>
              <NavLink to={navItem.link}>{navItem.title}</NavLink>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default MobileNav;
