export const basics = {
  appTitle: process.env.REACT_APP_CF_TITLE || "CF 2023",
  appDesc:
    process.env.REACT_APP_CF_DESC ||
    "Oficiální stránka letošního ročníku zasedání Celostátního fóra České pirátské strany, 29. 4. 2023.",
};

export const api = {
  baseUrl: process.env.REACT_APP_API_BASE_URL || "https://cf2023.pirati.cz/api",
};

export const eventHall = {
  title: "Dům Kultury Liberec (Rakev)",
  desc: "Zde bude probíhat jednání celostátního fóra.",
  coords: { lat: 50.7656583, lon: 15.0533914 },
  type: "event",
  group: "basics",
};

export const railwayStation = {
  title: "Vlakové nádrazí",
  desc: "Pro řadu lidí nejsnazší způsob dopravy do Liberce.",
  coords: { lat: 50.7617544, lon: 15.0466081 },
  type: "train",
  group: "transportation",
};

export const busStation = {
  title: "Autobusové nádrazí",
  desc: "Pro řadu lidí nejsnazší způsob dopravy do Liberce.",
  coords: { lat: 50.7633358, lon: 15.0476811 },
  type: "train",
  group: "transportation",
};


export const parkingForum = {
  title: "Parkovací dům Forum",
  desc: "Nejbližší parkoviště pro účastníky",
  coords: { lat: 50.7648083, lon: 15.0556956 },
  type: "location",
  group: "transportation",
};

export const parkingRakev = {
  title: "Parkoviště u KD pro novináře",
  desc: "Parkoviště je vyhrazeno pro novináře a hosty",
  coords: { lat: 50.7649881, lon: 15.0535442 },
  type: "location",
  group: "transportation",
};

export const map = {
  center: { lat: 50.7645131, lon: 15.0509800, zoom: 16 },
  markers: [eventHall, railwayStation, busStation, parkingForum, parkingRakev],
};

export const nav = [
  //  {
  //    link: "/registrace",
  //    title: "Registrace",
  //  },
  {
    link: "/ubytovani",
    title: "Ubytování",
  },
  //  {
  //    title: "Stravování",
  //  },
  {
    link: "/doprava",
    title: "Doprava",
  },
  {
    link: "/program",
    title: "Program",
  },
  {
    title: "Party",
    link: "/party",
  },
  {
    link: "/mapa",
    title: "Mapa",
  },
  {
    link: "/organizatori",
    title: "Organizátoři",
  },
//  {
//    link: "/volba-predsednictva",
//    title: "Volba předsednictva",
//  },
    {
      link: "/pro-dobrovolniky",
      title: "Pro dobrovolníky",
    },
];

export const organisers = [
  {
    title: "Organizační tým",
    members: [
      {
        name: "Hana Černošová",
        position: "Koordinátorka CF 2023, Koordinátorka dobrovolníků krajského sdružení",
        phone: "+420770113645",
        email: "hana.cernosova@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/liberecky/img/people/hana-cernosova_2.jpg",
      },
      {
        name: "Marek Förster",
        position: "Web, registrace, technické zajištění",
        phone: "+420775688305",
        email: "marek.forster@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/liberecky/img/people/marek-forster-ps.jpg",
      },
      {
        name: "Andrej Ramašeuski",
        position: "Technické zajištění",
        phone: "+420605234197",
        email: "andrej.ramaseuski@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/andrej-ramaseuski-2020.jpg",
      },
    ],
  },
];

export const accommodation = [
  {
    title: "Imperial Hotel Pytloun",
    link: "https://www.pytloungrandhotelimperial.cz/cs/",
    email: "event.coordinator@pytloun-hotels.cz",
    desc: "Luxusní a komfortní ubytování v nově zrekonstruovaném hotelu s restaurací na špičkové světové úrovni. Hotel poskytuje ubytování v pokojích kategorie Superior, Deluxe a Design.",
    coords: { lat: 50.764212858578944, lon: 15.050346117964015 },
    distance: 350,
    priceClass: "$$$",
    accessibility: {
      status: true,
      help: "Má bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 3150,
      },
      {
        bedCount: 2,
        price: 3150,
      },
      {
        bedCount: 4,
        price: 5250,
      },
    ],
  },
  {
    title: "Pytloun City Boutique Hotel",
    link: "http://www.pytlouncityboutiquehotel.cz/",
    email: "event.coordinator@pytloun-hotels.cz",
    desc: "Luxusní čtyřhvězdičkový hotel s moderním vybavením a designem, klimatizovanými pokoji a hlídaným krytým parkovištěm. K relaxaci a odpočinku je Vám v hotelu k dispozici relaxační centrum s nabídkou thajských masáží.",
    coords: { lat: 50.76644959387315, lon: 15.05883474187591 },
    distance: 450,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 2,
        price: 2625,
      },
    ],
  },
  {
    title: "Pytloun Design Hotel",
    link: "http://www.pytloundesignhotel.cz/",
    email: "event.coordinator@pytloun-hotels.cz",
    desc: "Hotel v klidné části města nedaleko centra Liberce. Všechny pokoje hotelu s osobitým designem jsou nekuřácké. Přihlášení do hotelu a odhlášení z hotelu je bezkontaktní a prostřednistvím kiosku. Snídaně jsou formou bufetu.",
    coords: { lat: 50.73690300420853, lon: 15.052313688646306 },
    distance: 4000,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 2,
        price: 2625,
      },
    ],
  },
  {
    title: "Pytloun Hotel Liberec",
    link: "http://www.pytlounhotelliberec.cz/",
    email: "event.coordinator@pytloun-hotels.cz",
    desc: "Elegantně a moderně zařízené pokoje. Součástí hotelu je zahradní terasa a dětské hřiště. Parkování u hotelu je zdarma. Přihlášení do hotelu a odhlášení z hotelu je bezkontaktní a prostřednistvím kiosku na místě ještě před Vaším příjezdem. Snídaně probíhají formou bufetu.",
    coords: { lat: 50.73690300420853, lon: 15.052313688646306 },
    distance: 3100,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 2,
        price: 2625,
      },
    ],
  },
  {
    title: "Hotel Liberec",
    link: "https://www.hotel-liberec.eu/",
    email: "recepce@hotel-liberec.cz",
    desc: "Hotel v centru Liberce, ubytování v nově rekonstruovaných pokojích.",
    coords: { lat: 50.769920415129036, lon: 15.061138655972323 },
    distance: 950,
    priceClass: "$$",
    accessibility: {
      status: true,
      help: "Má bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 1450,
      },
      {
        bedCount: 2,
        price: 1990,
      },
    ],
  },
  {
    title: "Koleje Technické Univerzity Liberec",
    link: "https://koleje.tul.cz/",
    email: "ladislava.palkovicova@tul.cz",
    desc: "Levné ubytování na kolejích technické univerzity.",
    coords: { lat: 50.770487681548964, lon: 15.08657425849264 },
    distance: 3500,
    priceClass: "$",
    accessibility: {
      status: true,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 380,
      },
      {
        bedCount: 2,
        price: 460,
      },
      {
        bedCount: 3,
        price: 770,
      },
    ],
  },
];

export const programGeneralSettings = {
  published: true,
};

export const program = {
  negotiation: {
    isUnderway: true,
    forumLink: "https://forum.pirati.cz/viewtopic.php?t=63086",
  },
  published: true,
  days: [
    {
      title: "sobota 29. 4. 2023",
      hourStart: 10,
      hourEnd: 24,
      // The smallest grid resolution in minutes. Take this into consideration when specifying time values.
      resolution: 5,
      tracks: [
        {
          code: "main",
          name: "Hlavní program",
          venue: "CF Online",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "voting",
          name: "Hlasování",
          venue: "Helios",
          colorBg: "black",
          colorFg: "white",
        },
      ],
      slots: [
        {
          time: "10:00",
          sessions: [
            {
              track: "main",
              timeStart: "10:00",
              timeEnd: "18:00",
              title: "Zasedání CF",
              agenda: [
                "",
                "",
                "",
                "",
              ],
            },
          ],
        },
        {
          time: "18:00",
          sessions: [
            {
              track: "main",
              timeStart: "18:00",
              timeEnd: "02:00",
              title: "Afterparty",
              agenda: [
                "",
                "",
                "",
                "",
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const partyBureauCandidates = [
  {
    postTitle: "Předsednický post",
    candidates: [
      {
        name: "Janka Michailidu",
        desc: "Rodačka z Petrovic u Karviné a postgraduální studentka VŠCHT, je dlouholetou členkou Pirátů, aktuálně je členkou republikového výboru a členkou správní rady nově založeného Pirátského institutu Pí. Zastávala pozici místopředsedkyně strany, krátkodobě v roce 2014 i pozici předsedkyně Pirátů. Podílela se na programových postojích Pirátů v oblasti psychotropních látek. Jejím cílem je, aby Piráti vyšli z nárazu do reality silnější než dřív a pokusili se nalézt syntézu mezi idealismem a pragmatismem.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786120#p786120",
        img: "janka-michailidu.jpg",
      },
      {
        name: "Ivan Bartoš",
        desc: "Doktor informační vědy z FFUK, informační architekt, jeden ze zakládajících členů Pirátské strany, hudebník, čtenář, hráč deskových her a dlouholetý předseda Pirátské strany Ivan Bartoš. Jako poslanec v minulém volebním období předsedal sněmovnímu výboru pro regionální rozvoj. Spolu s oblastí digitalizace se nyní bude agendě místního rozvoje věnovat coby ministr a místopředseda nastupující vlády. Piráti jsou podle něj liberální středovou stranou s kvalitním programem bránícím svobodu lidí, vzdělanost a soudržnost společnosti. Politika je prostředkem změny, nikoliv cílem.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786951#p786951",
        img: "ivan-bartos.jpg",
      },
      {
        name: "Lukáš Wagenknecht",
        desc: "Současný senátor za Prahu 8 a 18, ekonom, auditor a vysokoškolský lektor je aktivním bojovníkem proti korupci a finančním machinacím. V roce 2015 obdržel Cenu za odvahu od Nadačního fondu proti korupci za své dlouhodobé nekompromisní postoje v roli auditora, když ukázal na nesrovnalosti v Dopravním podniku v Praze nebo na Ministerstvu financí. Zastává postoj, že správnou cestou v politice je být blízko občanům, jít mezi lidi, bavit se s nimi a naslouchat jejich názorům. Uvnitř strany chce spojovat různé myšlenkové směry.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785845#p785845",
        img: "lukas-wagenknecht.jpg",
      },
      {
        name: "David Witosz ",
        desc: "Místostarosta MO Moravská Ostrava a Přívoz, radní města Ostravy a garant programového bodu doprava pro volby do poslanecké sněmovny 2017 a 2021. V rámci úkolů ve vedení městského obvodu jsou jeho prioritami obnova a revitalizace bytového a nebytového fondu, lepší kultivace veřejného prostoru z pohledu komunálního úklidu, řešení problematiky statické dopravy a nové kontrolní metody ve stavebních investicích. Budoucí předseda Pirátů by podle něj měl splnit dva základní politické axiomy: Strana Pirátů se nestane stranou jednoho muže a naučí se používat slovo NE.",
        link: "https://forum.pirati.cz/viewtopic.php?p=788403#p788403",
        img: "david-witosz.jpg",
      },
    ],
  },
  {
    postTitle: "Místopředsednický post",
    candidates: [
      {
        name: "Jiří Snížek",
        desc: "Vystudovaný elektroinženýr a filosof, zastupitel Středočeského kraje a náměstek hejtmanky pro regionální rozvoj a územní plánování pochází ze Záboří nad Labem a nyní žije v Kolíně. Mezi lety 2018 a 2020 působil jako předseda středočeských Pirátů. Z pozice místopředsedy chce zajistit víc zdrojů pro prezentaci místních sdružení a jejich častější prezentaci v centrálních komunikačních kanálech a také transparentnější fungování odborného zázemí strany.",
        link: "https://forum.pirati.cz/viewtopic.php?p=783216#p783216",
        img: "jiri-snizek.jpg",
      },
      {
        name: "Blanka Charvátová",
        desc: "Jednatelka republikového výboru, předsedkyně místního sdružení Piráti Praha 21 a zastupitelka tamtéž. Momentálně například pracuje jako vedoucí pražského dopravního týmu na přípravě programu pro pražské magistrátní volby v roce 2022. Jako členka republikového předsednictva by se ráda zasadila o zlepšení komunikace Pirátů dovnitř i navenek. Chce, aby Piráti začali být pro voliče čitelnou stranou a aby se naučili s voliči mluvit jasně a srozumitelně.",
        link: "https://forum.pirati.cz/viewtopic.php?p=783987#p783987",
        img: "blanka-charvatova.jpg",
      },
      {
        name: "Hana Hajnová",
        desc: "Již během studia sociologie a evropských studií působila jako projektová manažerka a pomáhala rozvíjet inovační ekosystém na jižní Moravě. Po návratu na Vysočinu se aktivně od roku 2014 angažuje u Pirátů, od kapitánky dračích lodí po první náměstkyni hejtmana pro regionální rozvoj. V republikovém předsednictvu se chce zaměřit na snížení administrativy, zlepšení vnitřní komunikace a rozvoj lidských zdrojů, protože lidé a čas, který jsou ochotni Pirátům věnovat, je to nejcennější, co máme.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785220#p785220",
        img: "hana-hajnova.jpg",
      },
      {
        name: "Sámir Shanaáh",
        desc: "Absolvent oboru podnikání a managementu a nynější student psychologie managementu v současnosti profesně působí jako obchodní manažer společnosti. Je koordinátorem fundraisingu Pirátů a v Berouně je předsedou místního sdružení. Mezi jeho priority v místopředsednické funkci patří udržení pirátských hodnot, zefektivnění vnitřních procesů, členský růst strany a restrukturalizace organizační struktury.",
        link: "https://forum.pirati.cz/viewtopic.php?f=350&t=59342",
        img: "samir-shanaah.jpg",
      },
      {
        name: "Marcel Kolaja",
        desc: "Produktový manažer, odborník na informační technologie a dlouhodobý člen Pirátů je od roku 2019 europoslancem a místopředsedou Evropského parlamentu. Ve svém politickém působení se zaměřuje na otevřené technologie, zachování svobody na Internetu, ochranu nezávislosti médií a transparentní a sjednocenou Evropu. Mezi zásady, které chce v předsednictvu Pirátů reprezentovat patří svoboda informací a ochrana občanských svobod a práv, zejména pak práva na soukromí. Sociálně-liberální politika Pirátů má myslet na budoucnost a nenechat lidi na pospas ani zvůli státu, ani zájmům nadnárodních korporací.",
        link: "https://forum.pirati.cz/viewtopic.php?f=350&t=59473",
        img: "marcel-kolaja.jpg",
      },
      {
        name: "Jana Koláříková",
        desc: "Dlouhodobá spolupracovnice a členka Pirátů působila jako koordinátorka dobrovolníků pro Jihočeský kraj a vedoucí Personálního odboru Pirátů. Může nabídnout také své zkušenosti s mezinárodní neziskovou pomocí v oblasti vzdělávání, zkušenosti s rozvojovými projekty nebo redaktorskou praxí. Na pozici místopředsedkyně chce klást důraz na kulturu komunikace a důvěry uvnitř strany, efektivní zapojení nových členů, rozvoj kompetencí potřebných pro výkon politických funkcí a podporu regionů z centra. Piráty vidí jako aktivní a sebevědomou stranu s vlastními politickými tématy.",
        link: "https://forum.pirati.cz/viewtopic.php?p=783766#p783766",
        img: "jana-kolarikova.jpg",
      },
      {
        name: "Vojtěch Lukavec",
        desc: "Student všeobecného lékařství působí jako člen Resortního týmu Zdravotnictví a místopředseda Mladého Pirátstva. Je členem zdravotního výboru Středočeského kraje a má profesní zkušenosti jako školitel primární prevence a zdravotní bratr na ARO. Zabývá se epidemiologickými tématy, preventivní medicínou a pracovními podmínkami zdravotnického personálu. Za klíčové úkoly nového vedení považuje zlepšení komunikace směrem k médiím, jednotné a koordinované vystupování a návrat k liberálně progresivní politické suverenitě strany.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786070#p786070",
        img: "vojtech-lukavec.jpg",
      },
      {
        name: "Filip Křistek",
        desc: "Člen meziresortního týmu Návykové chování a Krajského expertního týmu pro bezpečnost je zároveň studentem oboru Evropská diplomacie a Politologie. Ve své práci pro Piráty se dosud zabýval převážně marketingem v oblasti sociálních sítí, grafikou i celkovým chodem organizace. Jeho vize spočívá v návratu k původním hodnotám a kořenům Pirátů jako jsou legalizace, transparentní stát a společenská progrese. Uvnitř strany chce prosazovat zapojení mladší generace, lepší informovanost členstva a efektivnější spolupráci mezi krajem a centrální úrovní.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785576#p785576",
        img: "filip-kristek.jpg",
      },
      {
        name: "Martin Jiránek",
        desc: "Analytik a marketingový poradce firem byl během minulého volebního období poslancem a do roku 2019 též zastupitelem města Trutnov. V rámci poslaneckého klubu byl osobou zodpovědnou za oblast průmyslu a obchodu, telekomunikací a cestovního ruchu. Piráty chce vidět jako úspěšnou, pevně ukotvenou a pro voliče čitelnou středovou stranu. V republikovém předsednictvu chce proto řešit ujasnění si základních pirátských a politických hodnot, tedy jakou stranou v jakém státě chceme být, a k tomu přidat chytrou komunikaci ke členům i ven bez zbytečných střílení se do nohy.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786044#p786044",
        img: "martin-jiranek.jpg",
      },
      {
        name: "Petr Kapounek",
        desc: "Prostějovský zastupitel a předseda komise IT a Smart Region v Olomouckém kraji je povoláním projektový manažer v telekomunikační společnosti. Považuje se za libertariána a chce proto Česko směřovat k vzdělanostní společnosti a hodnotám svobody založeným na osobní odpovědnosti. Chce, aby Piráti přispívali k vytváření podmínek pro průhledný a efektivní stát bez šikany a ČR se tak mohla stát ekonomickým tygrem. Nechce, aby Piráti byli stranou vyššího dobra, která lidi převychovává pomocí omezení, nařízení a zákazů.",
        link: "https://forum.pirati.cz/viewtopic.php?f=350&t=59255",
        img: "kapounek-petr.jpg",
      },
      {
        name: "Antonino Milicia",
        desc: "Odborník se vzděláním a praxí v oblasti strategie, projektů a organizačního rozvoje. Piráti jsou pro něj jediná strana poctivé a autentické politiky, která se však musí poučit ze svých chyb. Své hlavní poslání vidí v nastavení strategického směřování a rozvoje strany, srozumitelné komunikaci programu a zlepšení vnitřní kultury a demokracie. Chce také usilovat o snížení byrokracie, vyšší akceschopnost vedení a účinnější zapojení členů. Jeho osobní vizí je pomoci z Pirátů vytvořit dobře fungující organismus, kde každý může být užitečný a s radostí pomáhat měnit svět k lepšímu.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786036#p786036",
        img: "antonino-milicia.jpg",
      },
      {
        name: "Lucie Cirkva Chocholová",
        desc: "Zastupitelka Středočeského kraje a předsedkyně Výboru pro památkovou péči, kulturu a cestovní ruch má přes dvacet let praxe v kultuře jak v soukromé, tak i veřejné sféře, včetně práce na Ministerstvu kultury. Piráty vnímá jako politickou sílu, která může navrátit důvěru lidí v politiku pomocí aplikace demokratických principů a zaměření na odbornost. Jejím cílem je reflektování současné liberální politiky a navázání na jádrová témata Pirátů. Chce co nejlépe využít lidský potenciál a zamezit vzniku osobních kultů.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785404#p785404",
        img: "lucie-chocholova.jpg",
      },
      {
        name: "Ondřej Profant",
        desc: "Dřívější poslanec a zastupitel hlavního města Prahy se po dobu svého politického působení zaměřuje na témata spojená s digitalizací a zavádění open source softwaru. Je aktivní i prostřednictvím spolku Otevřená města, zabývá se svobodnou kulturou a stál za pražským projektem otevřených dat. Na pozici místopředsedy chce přispět k vyřešení vnitrostranických sporů a budování značky Pirátů. Zároveň vidí jako jednu z výzev efektivní předávání vědomostí a schopností nových členů/členek a kandidátů/kandidátek.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785610#p785610",
        img: "ondrej-profant.jpg",
      },
      {
        name: "Jana Skopalíková",
        desc: "Místopředsedkyně krajského sdružení Středočeský kraj a současně radní pro životní prostředí a zemědělství Středočeského kraje. Vystudovaná botanička se zaměřuje na činnosti spojené s ochranou životního prostředí a sociální pomoc, jako jsou akce Ukliďme Česko nebo Pirátský guláš. V republikovém předsednictvu by chtěla přispět k větší mediální podpoře krajských a regionálních osobností, podpoře a školení s výhledem na komunální volby. Plánuje také lepší práci s nově přijatými členy.",
        link: "https://forum.pirati.cz/viewtopic.php?p=788454#p788454",
        img: "jana-skopalikova.jpg",
      },
      {
        name: "Dalibor Záhora",
        desc: "Zakládající člen Pirátů, který se již od devadesátých let věnuje nezávislým kulturním i politickým aktivitám, spolupracuje i s nevládními organizacemi bránícími práva spoluobčanů. Stál při vzniku Pirátských novin a podílel se na budování středočeské organizace Pirátů. Inicioval také kandidaturu senátorky Adély Šípové. Jako zásadní problém, který chce řešit, vidí centralizaci strany, jíž chce čelit návratem rozhodovacích pravomocí do stranické struktury. Chce zajistit, aby nebyla omezována práva řadových členů a členek a byla brána vedením strany na vědomí.",
        link: "https://forum.pirati.cz/viewtopic.php?p=787584#p787584",
        img: "dalibor-zahora.jpg",
      },
      {
        name: "Jiří Knotek",
        desc: "Absolvent Politologie a Evropských studií na Univerzitě Palackého v Olomouci se s Piráty účastnil několika předvolebních kampaní. Aktuálně je zlínským krajským zastupitelem a předsedou výboru pro kulturu a památky. Předmětem jeho zájmu je zahraniční a bezpečnostní politika ČR a mezinárodní vztahy. Jako místopředseda se chce aktivně zapojit do komunikace a reprezentace Pirátů navenek a pomáhat kandidátům do komunálních voleb s kampaní a konzultovat předvolební kroky.",
        link: "https://forum.pirati.cz/viewtopic.php?p=784663#p784663",
        img: "jiri-knotek.jpg",
      },
      {
        name: "Martin Kučera",
        desc: "Zastupitel městského obvodu Moravská Ostrava a Přívoz je dlouholetým Pirátem, dřívějším místopředsedou nebo vedoucím administrativniho odboru. Je vystudovaný matematický analytik, v legislativě ho zajímají především oblasti související s tvůrčí činností a jejími dopady na školství či zdravotnictví, věnuje se též problematice internetu či stavu životního prostředí. V předsednictvu chce hájit principy kontroly práce funkcionářů, ochranu soukromí občanů, včetně obhajoby svobody na internetu proti cenzuře. Chce dosáhnout revize kopírovacího monopolu v podmínkách 21. století a svobody pěstování a zpracování konopí.",
        link: "https://forum.pirati.cz/viewtopic.php?p=787040#p787040",
        img: "martin-kucera.jpg",
      },
      {
        name: "Šárka Václavíková",
        desc: "Předsedkyně Young Pirates of Europe působila jako koordinátorka kampaně pro komunální volby 2018 nebo jako volební manažerka Jiřího Kadeřávka. V Moravskoslezském kraji byla koordinátorkou pro krajské volby 2020, mimo to se věnovala občanskému aktivismu. V současnosti je asistentkou pražského radního Víta Šimrala. Jako místopředsedkyně bude klást důraz na rovné šance a práva, svobodu pro všechny bez rozdílu, legalizaci a transparenci. Též stojí o lepší mezinárodní propojení Pirátů.",
        link: "https://forum.pirati.cz/viewtopic.php?p=788634#p788634",
        img: "sarka-vaclavikova.jpg",
      },
    ],
  },
];
