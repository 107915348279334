const Badge = ({ avatarUrl, name, position, email, phone }) => {
  return (
    <div className="badge">
      <div className="avatar badge__avatar avatar--md">
        <img src={avatarUrl} alt="Andrea Linhartová" />
      </div>

      <div className="badge__body">
        <h2 className="head-heavy-xs badge__title">{name}</h2>
        <p className="badge__occupation">{position}</p>
        {phone && (
          <a
            href={"tel:" + phone}
            className="contact-line icon-link content-block--nostyle contact-line--responsive badge__link"
          >
            <i className="ico--phone"></i>
            <span>{phone}</span>
          </a>
        )}
        {email && (
          <a
            href={"mailto:" + email}
            className="contact-line icon-link content-block--nostyle contact-line--responsive badge__link"
          >
            <i className="ico--envelope"></i>
            <span>{email}</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default Badge;
