import React from "react";
import classNames from "classnames";

const CardBodyText = ({ children, className }) => {
  return (
    <div className={classNames("card-body-text", className)}>{children}</div>
  );
};

export default CardBodyText;
