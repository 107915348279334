import Keycloak from "keycloak-js";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: "https://auth.pirati.cz/auth",
  realm: "pirati",
  clientId: "cf2022",
});

export default keycloak;
