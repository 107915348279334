import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import classNames from "classnames";
import fetch from "unfetch";

import Button from "components/Button";
import PageTitle from "components/PageTitle";
import RegistrationForm from "components/RegistrationForm";
import { api, basics } from "config";

const resolveAudience = (keycloak) => {
  if (!keycloak.authenticated) {
    return undefined;
  }

  if (keycloak.tokenParsed.groups) {
    const hasMemberGroup =
      keycloak.tokenParsed.groups.filter((g) => g.match(".*:f")).length > 0;
    if (hasMemberGroup) {
      return "member";
    }
  }

  return undefined;
};

const Registration = () => {
  const { keycloak } = useKeycloak();
  const [questions, setQuestions] = useState();
  const [audience, setAudience] = useState(resolveAudience(keycloak));
  const [formData, setFormData] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState();
  const [submitDuplicity, setSubmitDuplicity] = useState(false);
  const [registrationFormRenderKey, setRegistrationFormRenderKey] = useState(0);

  useEffect(() => {
    const doFetch = async () => {
      const [allQuestions] = await Promise.all([
        fetch(`${api.baseUrl}/questions`).then((r) => r.json()),
      ]);

      setQuestions(allQuestions);
    };

    doFetch();
  }, []);

  const submit = async () => {
    setSubmitting(true);
    setSubmitError(null);
    setSubmitDuplicity(false);

    try {
      const payload = formData;

      payload.products.push(
        {
          member: {
            id: 1,
          },
          guest: {
            id: 2,
          },
          journalist: {
            id: 3,
          },
        }[audience]
      );

      if (keycloak.token) {
        payload.token = keycloak.token;
      }

      const resp = await fetch(`${api.baseUrl}/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!resp.ok) {
        const error = new Error(resp.statusText);
        error.response = resp;
        throw error;
      }

      setSubmitError(null);
      setSubmitted(true);
    } catch (err) {
      console.error(err);

      const resp = await err.response.json();

      if (resp.errors.filter((err) => err.message === "Duplicity").length > 0) {
        setSubmitDuplicity(true);
      } else {
        setSubmitError(err);
      }

      setSubmitted(false);
    } finally {
      setSubmitting(false);
    }
  };

  const resetAudience = () => {
    setAudience(undefined);
    setFormData(undefined);
    setRegistrationFormRenderKey(registrationFormRenderKey + 1);
  };

  return (
    <article className="space-y-8 w-full">
      <PageTitle>Registrace</PageTitle>

      <Helmet>
        <title>Registrace | {basics.appTitle}</title>
        <meta property="og:title" content="Registrace" />
      </Helmet>

      <p className="leading-normal">
        Pokud se chcete zúčastnit {basics.appTitle}, vyplňte prosím registrační
        formulář.
      </p>

      <h2 className="head-heavy-sm text-violet-400">Pozor! Registrační formulář je v testovacím provozu, ostré registrace budou teprve spuštěny. Jakékoliv provedené registrace budou ignorovány a zapomenuty.</h2>

      {!submitted && (
        <div className="space-y-8">
          <h2 className="head-heavy-sm">1. Jaký máte vztah k Pirátům?</h2>

          {!audience && (
            <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
              <Button
                className="btn--black max-w-full sm:max-w-xs lg:max-w-full"
                bodyClassName="whitespace-no-wrap"
                color="black"
                routerTo="/registrace"
                onClick={keycloak.login}
              >
                Jsem člen/členka strany
                <span className="hidden lg:inline">
                  &nbsp;(vyžaduje přihlášení)
                </span>
              </Button>
              <Button
                className="btn--blue-300 max-w-full sm:max-w-xs lg:max-w-full"
                bodyClassName="whitespace-no-wrap"
                color="black"
                routerTo="/registrace"
                onClick={() => setAudience("guest")}
              >
                Jsem host
              </Button>
              <Button
                className="btn--violet-400 max-w-full sm:max-w-xs lg:max-w-full"
                bodyClassName="whitespace-no-wrap"
                color="black"
                routerTo="/registrace"
                onClick={() => setAudience("journalist")}
              >
                Jsem novinář
              </Button>
            </div>
          )}
          {audience && (
            <div
              className={classNames(
                "alert alert--grey-125 leading-normal space-x-4",
                { "items-center": audience !== "member" }
              )}
            >
              <span className="ico--checkbox-checked text-3xl" />
              <div>
                {audience === "member" && (
                  <>
                    <p>
                      Registrujete se jako{" "}
                      <strong>{keycloak.tokenParsed.name}</strong>.
                    </p>
                    <p>
                      Použili jsme účet svázaný s vaší pirátskou identitou.
                      Pokud se snad chcete přihlásit pod jinou identitou, je
                      nutné se nejprve{" "}
                      <button className="underline" onClick={keycloak.logout}>
                        odhlásit
                      </button>
                      .
                    </p>
                  </>
                )}
                {audience === "guest" && (
                  <p>
                    Registrujete se jako <strong>host</strong>. Něco nesedí? Tak
                    to{" "}
                    <button className="underline" onClick={resetAudience}>
                      zkuste znovu
                    </button>
                    .
                  </p>
                )}
                {audience === "journalist" && (
                  <p>
                    Registrujete se jako <strong>novinář</strong>. Něco nesedí?
                    Tak to{" "}
                    <button className="underline" onClick={resetAudience}>
                      zkuste znovu
                    </button>
                    .
                  </p>
                )}
              </div>
            </div>
          )}

          {audience && questions && (
            <>
              <h2 className="head-heavy-sm">
                2. Nyní prosím vyplňte registrační formulář
              </h2>
              <RegistrationForm
                key={registrationFormRenderKey}
                fixedName={
                  audience === "member" ? keycloak.tokenParsed.name : null
                }
                fixedEmail={
                  audience === "member" ? keycloak.tokenParsed.email : null
                }
                showOrganization={audience !== "member"}
                showQuestions={audience !== "journalist"}
                showLunch={audience !== "journalist"}
                showParties={audience !== "journalist"}
                showPrintouts={audience !== "journalist"}
                showDonation={audience !== "journalist"}
                questions={questions}
                onSubmit={setFormData}
                className={classNames({ hidden: !!formData })}
              />
              {formData && (
                <div className="alert alert--grey-125 leading-normal items-center space-x-4">
                  <span className="ico--checkbox-checked text-3xl" />
                  <p>
                    Hotovo, vyplněno! Něco nesedí? Tak to{" "}
                    <button
                      className="underline"
                      onClick={() => setFormData(undefined)}
                    >
                      můžete upravit
                    </button>
                    .
                  </p>
                </div>
              )}
            </>
          )}

          {formData && (
            <>
              <h2 className="head-heavy-sm">3. Dokončení</h2>
              <div className="space-y-4">
                <p className="leading-normal">
                  Nyní už jen zbývá vše potvrdit. Pokud vše souhlasí, klikněte
                  na tlačítko níže.
                </p>
                {submitError && (
                  <p className="alert alert--error leading-normal">
                    Je nám líto, ale při odesílání došlo k nějakému problému,
                    zkuste to prosím ještě jednou.
                  </p>
                )}
                {submitDuplicity && (
                  <p className="alert alert--dark leading-normal">
                    <span>
                      Zdá se, že{" "}
                      <strong>registraci pro váš email již máme</strong>. Pokud
                      si přejete registraci nějak upravit, kontaktujte prosím
                      někoho{" "}
                      <Link to="/organizatori" className="underline">
                        z organizátorů
                      </Link>
                      .
                    </span>
                  </p>
                )}
                <Button
                  className="text-lg max-w-full col-span-1"
                  bodyClassName="whitespace-no-wrap"
                  color="black"
                  type="submit"
                  loading={submitting}
                  onClick={submit}
                >
                  Dokončit registraci
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      {submitted && (
        <div className="alert alert--blue-300 leading-normal space-x-4">
          <span className="ico--checkbox-checked text-3xl" />
          <div>
            <h2 className="head-heavy-sm mb-2">
              Vaše registrace byla úspěšná, těšíme se naviděnou!
            </h2>
            <p>
              Nyní prosím vyčkejte na odkaz ke stažení vstupenek, které budete
              potřebovat při vstupu do jednacího sálu. Mějte prosím strpení, k
              odeslání odkazu <strong>může dojít až za několik dní</strong>,
              některé registrace totiž musíme osobně zkontrolovat.
            </p>
            <p>
              Pokud jste v registračním formuláři zvolil/a některé placené
              položky (např. stravování nebo tištěné materiály), součástí odkazu
              ke stažení vstupenek bude též odkaz k provedení platby.
            </p>
          </div>
        </div>
      )}
    </article>
  );
};

export default Registration;
