import React from "react";
import { Helmet } from "react-helmet";
import { MapContainer, TileLayer } from "react-leaflet";

import MarkerItem from "components/MarkerItem";
import PageTitle from "components/PageTitle";
import { basics, map } from "config";

const Transportation = () => {
  const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  return (
    <article className="space-y-8 w-full">
      <PageTitle>Doprava</PageTitle>
      <Helmet>
        <title>Doprava | {basics.appTitle}</title>
        <meta property="og:title" content="Mapa" />
      </Helmet>

      <div className="content-block">
        <h2>Kde jednání probíhá?</h2>
      </div>

      <div className="grid grid-cols-4 gap-4">
        <MapContainer
          center={[map.center.lat, map.center.lon]}
          zoom={map.center.zoom}
          scrollWheelZoom={true}
          style={{ height: "25rem", width: "100%" }}
          className="col-span-4"
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/xaralis/ck4oblwty0fgk1fjzxmqow2r5/tiles/256/{z}/{x}/{y}@2x?access_token=${accessToken}`}
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
          />
          {map.markers.map((marker) => (
            <MarkerItem
              key={marker.title}
              type={marker.type}
              coords={marker.coords}
              desc={
                <>
                  <strong>{marker.title}</strong>
                  <div>{marker.desc}</div>
                </>
              }
            />
          ))}
        </MapContainer>
        <div className="content-block col-span-4">
          <p>
            <a
              href="https://www.dkliberec.cz/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Dům Kultury Liberec
            </a>
            <br />
            Soukenné náměstí 613
            <br />
            146 00 Liberec
            <br />
            GPS: 50.7656769N, 15.0533539E
          </p>
        </div>
      </div>

      <div className="content-block">
        <h2>Jak se tam dostanu?</h2>

        <h3>Autobusem</h3>

        <p>
          Autobusem se dá do Liberce cestovat ze všech koutů republiky, z Prahy trvá cesta autobusem běžně 75 minut a jezdí v intervalech 15 až 30 minut. Přímé autobusové linky do Liberce jezdí např. z Brna, Olomouce, Litomyšle, Hradce Králové a Děčína.
          <br/><br/>
          Autobusové nádraží se nachází cca 600m od místa pořádání zasedání, dá se z něj hravě dojít pěšky během 10 minut.
        </p>

        <h3>Vlakem</h3>

        <p>
          Liberec bohužel není součástí hlavního železničního koridoru a efektivní vlakové spojení severu republiky s Prahou se stále jen plánuje. Aktuálně cesta vlakem z Prahy trvá cca 150 minut. 
          Solidně se dá vlakem cestovat z Hradce Králové (150 minut), Pardubic (170 minut), Děčína (105 minut) nebo Ústí nad Labem (120 minut).
          <br/><br/>
          Liberecké vlakové nádraží se nachází cca 800m od místa pořádání zasedání, dá se z něj hravě dojít pěšky během 12 minut nebo použít tramvaj do stanice Rybníček, která se nachází v bezprostřední blízkosti Domu Kultury, cesta tramvají trvá přibližně 2 minuty.
        </p>

        <h3>Autem</h3>
        <p>
          Pokud zvolíte dopravu po vlastní ose, ideální spojení je po dálnici D10 z Prahy, cesta trvá něco přes hodinu. 
        </p>

        <h2>A jak v Liberci?</h2>

        <h3>Parkování</h3>
        <p>
        Zaparkovat můžete v parkovacím domě v obchodním centru Forum, které sousedí s místem zasedání. Pro novináře a hosty je vyhrazené parkování přímo u Kulturního Domu, počet parkovacích míst je ale omezené.<br/>
        Ubytovaným na hotelech doporučujeme možnosti parkování u hotelu.
        </p>

        <h3>MHD</h3>
        <p>
        Dopravní podnik provozuje <a href="https://www.dpmlj.cz/mhd-liberec/informace/plan-site" target="_blank" rel="noreferrer noopener">hustou síť tramvají a autobusů</a>. Platit za jízdné v Liberci se tak dá pomocí mobilního telefonu a to dvěma základními způsoby: 
          <ul>
              <li>
                <b>SMS Jízdenka</b><br/>
                SMS s textem: LIB25 odeslat na telefonní číslo 90206<br/>
                Cena: 25 Kč<br/> 
                Platnost: 60 minut<br/>
              </li>
              <li>
                <b>Datová jízdenka</b><br/>
                Platba různými aplikacemi - podrobnosti <a href="https://www.dpmlj.cz/mhd-liberec/informace/sms-jizdenka" target="_blank" rel="noreferrer noopener">zde</a>
              </li>
          </ul>
        </p>
      </div>
    </article>
  );
};

export default Transportation;
